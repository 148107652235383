import React, { Fragment } from "react"
import styled from "styled-components"
import { colors } from "./../styles/colors"
import { toHTML } from "./../helpers/index"
import { deviceMax } from "./../styles/mediaqueries"
import ArticleContact from "./articleContact"
import { fontweight } from "./../styles/variables"
import { MdArrowForward } from "react-icons/md"
import { IsNotNullOrUndefined } from "../utils/isNotNullOrUndefined"

const StyledArticleHeader = styled.div`
    h1 {
        font-size: 48px;
        font-weight: ${fontweight.xtrabold};
        color: ${colors.darkBlue};
        padding: 1rem 0;
        line-height: 74px;
        @media ${deviceMax.tablet} {
            font-size: 45px;
            line-height: 71px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 28px;
            line-height: 38px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 23px;
            line-height: 32px;
        }
    }
`
const StyledArticleHeaderSubtitle = styled.div`
    h2 {
        font-size: 22px;
        font-weight: ${fontweight.semibold};
        color: ${colors.royalBlue};
        line-height: 38px;
        @media ${deviceMax.mobileM} {
            font-size: 30px;
            line-height: 38px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 23px;
            line-height: 30px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 19px;
            line-height: 24px;
        }
    }
`

const StyledArticleHeaderWrapper = styled.div<{ includePadding?: boolean }>`
    display: flex;
    flex-direction: column;
    padding-bottom: ${(props) => props.includePadding && "2rem"};
`

const StyledArticleContent = styled.div`
    p {
        padding: 1rem 0;
        font-size: 18px;
        font-weight: ${fontweight.regular};
        color: ${colors.royalBlue};
        line-height: 36px;
        @media ${deviceMax.tablet} {
            font-size: 20px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 20px;
            line-height: 31px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 16px;
            line-height: 27px;
        }
    }

    ul {
        list-style: inside;
        margin-left: 1rem;
        > li > a {
            font-size: 18px;
        }
    }
    ol {
        list-style-type: decimal;
        margin-left: 1rem;
        > li > a {
            font-size: 18px;
            margin-left: 0.5rem;
        }
    }

    h3 {
        padding: 2rem 0;
        font-size: 25px;
        font-weight: ${fontweight.bold};
        color: ${colors.royalBlue};
        @media ${deviceMax.tablet} {
            font-size: 24px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 23px;
            line-height: 30px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 18px;
            line-height: 25px;
        }
    }

    h4 {
        padding: 1rem 0;
        font-size: 19px;
        font-weight: ${fontweight.bold};
        color: ${colors.textMediumBlue};
        @media ${deviceMax.tablet} {
            font-size: 20px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 20px;
            line-height: 28px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 17px;
            line-height: 23px;
        }
    }
`

const StyledArticleContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
`

const StyledArticleSeparator = styled.span`
    display: flex;
    height: 8px;
    background: ${colors.skyBlue};
    width: 80px;
`

const StyledArticleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    @media ${deviceMax.laptopL} {
        padding: 0 5rem;
    }
    @media ${deviceMax.tablet} {
        padding: 0 2.5rem;
    }
    @media ${deviceMax.mobileL} {
        padding: 0 2rem;
    }
`

const StyledArticleOuterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${deviceMax.laptopL} {
        margin: 0 5rem;
    }
    @media ${deviceMax.laptopNarrow} {
        margin: 0 2rem;
    }
    @media ${deviceMax.laptopL} {
        margin: 0rem;
    }
`

const StyledLinkContainer = styled.div`
    padding: 2rem 4rem;
    background-color: #e4f0ff;
    max-width: 90vw;
    @media ${deviceMax.mobileL} {
        padding: 2rem 2rem;
        .link-container > a.wrapper-link {
            > svg {
                width: 22px;
            }
            > div.link > p {
                font-size: 17px;
            }
        }
    }
    @media ${deviceMax.mobileS} {
        padding: 2rem 1rem;
        .link-container > a.wrapper-link {
            > svg {
                width: 23px;
            }
            > div.link > p > a {
                font-size: 16px;
            }
        }
    }

    .bbl-title {
        font-size: 26px;
        font-weight: ${fontweight.bold};
        color: ${colors.textMediumBlue};
    }
    > .link-wrapper {
        margin: 2.5rem 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);
        grid-gap: 10px;
        justify-content: space-between;
        > .link-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 1.5rem 0;
            width: max-content;
            &:hover {
                > a.wrapper-link {
                    > svg {
                        transform: translateX(10px);
                        color: ${colors.darkBlue};
                    }
                    > div.link > p > a {
                        color: ${colors.darkBlue};
                        @media ${deviceMax.mobileS} {
                            font-size: 14px;
                        }
                    }
                }
            }
            > a {
                display: flex;
                flex-direction: row;
                align-items: center;
                > svg {
                    transition: transform 0.2s ease-in-out;
                    color: ${colors.textMediumBlue};
                    height: 100%;
                    width: 26px;
                }
                > div > p {
                    margin-left: 1rem;
                    font-size: 18px;
                    font-weight: ${fontweight.semibold};
                    color: ${colors.textMediumBlue};
                    display: flex;
                    max-width: 10em;
                }
            }
        }
    }
`

interface IArticleProps {
    heading?: string
    subtitle?: string
    content?: string
    contact?: string
    contactEmail?: string
    contactTel?: string
    contactPicture?: any
    additionalFiles?: any[]
    slimImage?: boolean
}

const Article: React.FC<IArticleProps> = ({
    heading,
    subtitle,
    content,
    contact,
    contactEmail,
    contactTel,
    contactPicture,
    additionalFiles,
    slimImage,
}: IArticleProps) => {
    let contactInfo = { contact, contactEmail, contactTel }

    const contentHtml: any = toHTML(content)

    return (
        <Fragment>
            <StyledArticleOuterContainer>
                <StyledArticleWrapper>
                    <StyledArticleHeaderWrapper
                        includePadding={IsNotNullOrUndefined(heading)}
                    >
                        {IsNotNullOrUndefined(heading) && (
                            <StyledArticleHeader>
                                <h1>{heading}</h1>
                            </StyledArticleHeader>
                        )}
                        {IsNotNullOrUndefined(subtitle) && (
                            <StyledArticleHeaderSubtitle>
                                <h2>{subtitle}</h2>
                            </StyledArticleHeaderSubtitle>
                        )}
                    </StyledArticleHeaderWrapper>
                    {IsNotNullOrUndefined(heading) &&
                        IsNotNullOrUndefined(subtitle) && (
                            <StyledArticleSeparator />
                        )}
                    <StyledArticleContentWrapper>
                        <StyledArticleContent
                            dangerouslySetInnerHTML={{ __html: contentHtml }}
                        ></StyledArticleContent>
                    </StyledArticleContentWrapper>
                    <ArticleContact
                        slimImage={slimImage && slimImage}
                        text={contactInfo && contactInfo}
                        image={contactPicture && contactPicture}
                    />
                    {additionalFiles && (
                        <StyledLinkContainer>
                            <p className="bbl-title">Nyttige lenker</p>

                            <div className="link-wrapper">
                                {additionalFiles.map((file: any) => {
                                    return (
                                        <div
                                            className="link-container"
                                            key={file?.title}
                                        >
                                            <a
                                                className="wrapper-link"
                                                href={
                                                    file?.fileurl?.relativePath
                                                        ? `/assets/${file.fileurl.relativePath}`
                                                        : file.url
                                                }
                                                target="_blank"
                                            >
                                                <MdArrowForward />
                                                <div className="link">
                                                    <p>{file.title}</p>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </StyledLinkContainer>
                    )}
                </StyledArticleWrapper>
            </StyledArticleOuterContainer>
        </Fragment>
    )
}

export default Article
